<template>
    <router-view>
    </router-view>
</template>
<script>///begin
    export default {
        name: "App",
        mounted() {
            this.getPlatformLanguages();
        },
        methods: {
            getPlatformLanguages() {
                this.$store.dispatch('language/get');
            },
        },
    };</script>
<style>
    /* gwfh.mranftl.com/fonts/montserrat?subsets=cyrillic,cyrillic-ext,latin,latin-ext */

    /* montserrat-regular - cyrillic_cyrillic-ext_latin_latin-ext */
    @font-face {
        font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        src: url('./assets/fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    }
    /* montserrat-700 - cyrillic_cyrillic-ext_latin_latin-ext */
    @font-face {
        font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        src: url('./assets/fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    }

</style>
