import sStorage from './storage';
import store from "../store";

class TokenService {

	getUser() {
		try {
			let user = sStorage.getItem('stored');
			if (!user) {
				return null;
			}
			return user;
		}
		catch (e) {
			this.removeUser();
			return null;
		}
	}

	setUser(user) {
		sStorage.setItem('stored', user);
	}

	removeUser() {
		sStorage.clear();
	}

	getLocalAccessToken() {
		let user = this.getUser();
		return user?.jwtToken;
	}

	updateToken(data) {
		let user = this.getUser();
		if (user) {
			user.jwtToken = data.jwtToken;
			this.setUser(user);
		}
		else {
			this.setUser(data);
		}
	}

}

export default new TokenService();
