import api from "./api";
import TokenService from "./token.service";
import store from "../store";

class AuthService {
	login({ email, password }) {
		return api
			.post("/auth/authenticate", { email, password })
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return error;
			});
	}

	loginExt(login) {
		return api
			.post("/auth/login-external", login)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return error;
			});
	}

	getExternalLogins() {
		return api
			.post("/auth/get-external-logins")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	logout() {
		return api.post("/auth/revoketoken");
		TokenService.removeUser();
	}

	getNotifications(input) {
		return api
			.post("/auth/get-notifications", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	checkEmail(input) {
		return api
			.post("/auth/check-email", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	resetPassword(input) {
		return api
			.post("/auth/reset-password", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return error;
			});
	}

	pingServer() {
		if (store.state.auth.user) {
			return api.get('/auth/ping');
		}
		else {
			return Promise.resolve({ data: null });
		}
	}
}

export default new AuthService();
