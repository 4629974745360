<template>
    <svg class="flag" :title="title" v-if="flagCode==='lt'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5 3"><path fill="#BE3A34" d="M0 0h5v3H0z" /><path fill="#046A38" d="M0 0h5v2H0z" /><path fill="#FFB81C" d="M0 0h5v1H0z" /></svg>
    <svg class="flag" :title="title" v-else-if="flagCode==='lv'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 3"><path fill="#9D2235" d="M0 0h6v3H0" /><path fill="#FFF" d="M0 1.2h6v.6H0" /></svg>
    <svg class="flag" :title="title" v-else-if="flagCode==='ee'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 21"><path fill="#FFF" d="M0 0h33v21H0z" /><path d="M0 0h33v14H0z" /><path fill="#0072CE" d="M0 0h33v7H0z" /></svg>
    <svg class="flag" :title="title" v-else-if="flagCode==='us'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 7410 3900"><path fill="#b22234" d="M0 0h7410v3900H0z" /><path d="M0 450h7410m0 600H0m0 600h7410m0 600H0m0 600h7410m0 600H0" stroke="#fff" stroke-width="300" /><path fill="#3c3b6e" d="M0 0h2964v2100H0z" /><g fill="#fff"><g id="d"><g id="c"><g id="e"><g id="b"><path id="a" d="M247 90l70.534 217.082-184.66-134.164h228.253L176.466 307.082z" /><use xlink:href="#a" y="420" /><use xlink:href="#a" y="840" /><use xlink:href="#a" y="1260" /></g><use xlink:href="#a" y="1680" /></g><use xlink:href="#b" x="247" y="210" /></g><use xlink:href="#c" x="494" /></g><use xlink:href="#d" x="988" /><use xlink:href="#c" x="1976" /><use xlink:href="#e" x="2470" /></g></svg>
    <svg class="flag" :title="title" v-else-if="flagCode==='ph'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 180 90"><path fill="#0038a8" d="M0 0h180v90H0z" /><path fill="#ce1126" d="M0 45h180v45H0z" /><path d="M77.942 45L0 90V0" fill="#fff" /><g transform="translate(28 45)" fill="#fcd116"><circle r="9" /><g id="d"><g id="c"><g id="b"><path d="M-1 0l.062.062L0 0l-.938-.062z" transform="scale(19)" /><path id="a" d="M-.884.116l.05.05L0 0z" transform="scale(19.2381)" /><use xlink:href="#a" transform="scale(1 -1)" /></g><use xlink:href="#b" transform="rotate(45)" /></g><use xlink:href="#c" transform="rotate(90)" /></g><use xlink:href="#d" transform="rotate(180)" /><g transform="translate(-2.02)"><g id="f" transform="translate(37.962)"><path id="e" d="M5 0L1.618 1.176l-.073 3.58-2.163-2.854-3.427 1.037L-2 0z" /><use xlink:href="#e" transform="scale(1 -1)" /></g><use xlink:href="#f" transform="rotate(120)" /><use xlink:href="#f" transform="rotate(-120)" /></g></g></svg>
    <svg :title="title" v-else-if="flagCode==='--'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24"><path d="M16.36,14C16.44,13.34 16.5,12.68 16.5,12C16.5,11.32 16.44,10.66 16.36,10H19.74C19.9,10.64 20,11.31 20,12C20,12.69 19.9,13.36 19.74,14M14.59,19.56C15.19,18.45 15.65,17.25 15.97,16H18.92C17.96,17.65 16.43,18.93 14.59,19.56M14.34,14H9.66C9.56,13.34 9.5,12.68 9.5,12C9.5,11.32 9.56,10.65 9.66,10H14.34C14.43,10.65 14.5,11.32 14.5,12C14.5,12.68 14.43,13.34 14.34,14M12,19.96C11.17,18.76 10.5,17.43 10.09,16H13.91C13.5,17.43 12.83,18.76 12,19.96M8,8H5.08C6.03,6.34 7.57,5.06 9.4,4.44C8.8,5.55 8.35,6.75 8,8M5.08,16H8C8.35,17.25 8.8,18.45 9.4,19.56C7.57,18.93 6.03,17.65 5.08,16M4.26,14C4.1,13.36 4,12.69 4,12C4,11.31 4.1,10.64 4.26,10H7.64C7.56,10.66 7.5,11.32 7.5,12C7.5,12.68 7.56,13.34 7.64,14M12,4.03C12.83,5.23 13.5,6.57 13.91,8H10.09C10.5,6.57 11.17,5.23 12,4.03M18.92,8H15.97C15.65,6.75 15.19,5.55 14.59,4.44C16.43,5.07 17.96,6.34 18.92,8M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" /></svg>
    <img class="flag" :title="title" v-else-if="flagCode!==''" :src="'/images/flags/' + flagCode + '.svg'" />
    <svg class="flag" :title="title" v-else style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M14.4,6L14,4H5V21H7V14H12.6L13,16H20V6H14.4Z" /></svg>
</template>
<script>///begin


    export default {
        props: {
            code: {
                type: String, default: ''
            },
            title: {
                type: String, default: ''
            },
        },
        computed: {
            flagCode: function () {
                return this.code ? this.code.toLowerCase() : '';
            },
        }
    }</script>
<style scoped>
    .flag {
        display: inline-block;
        vertical-align: baseline;
        margin-bottom: -2px;
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
        border: solid 1px rgb(147,147,147,.5);
        object-fit: scale-down;
    }
</style>