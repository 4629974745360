import { createRouter, createWebHistory } from 'vue-router'
import store from '../store';
import TokenService from '../services/token.service';
import api from "../services/api";

import Login from '../views/Login.vue'

/*
function dynamicPropsFn(route) {
	const now = new Date()
	return {
		name: (now.getFullYear() + parseInt(route.params.id)) + '!',
		piid: 666
	}
}

 meta: { requiresAuth: false }

*/

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import('../views/Home.vue'),
		meta: { sideBar: 'Home' }
	},
	{
		path: '/config',
		name: 'config',
		component: () => import('../views/SystemConfig.vue'),
		meta: { sideBar: 'Config' }
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
	},
	{
		path: '/teams',
		name: 'teams',
		component: () => import('../views/Teams.vue'),
		meta: { sideBar: 'Teams' }
	},
	{
		path: '/team/:id',
		name: 'team',
		component: () => import('../views/Team.vue'),
		props: true,
		meta: { sideBar: 'Teams' }
	},
	{
		path: '/games',
		name: 'games',
		component: () => import('../views/Games.vue'),
		meta: { sideBar: 'Games' }
	},
	{
		path: '/game/:id',
		name: 'game',
		component: () => import('../views/Game.vue'),
		props: true,
		meta: { sideBar: 'Games' }
	},
	{
		path: '/club',
		name: 'club',
		component: () => import('../views/Club.vue'),
		meta: { sideBar: 'Club' }
	},
	{
		path: '/club-personnel',
		name: 'club-personnel',
		component: () => import('../views/ClubPersonnel.vue'),
		meta: { sideBar: 'Club' }
	},
	{
		path: '/club-ambassadors',
		name: 'club-ambassadors',
		component: () => import('../views/ClubAmbassadors.vue'),
		meta: { sideBar: 'Club' }
	},
	{
		path: '/club-ambassador/:id',
		name: 'club-ambassador',
		component: () => import('../views/ClubAmbassador.vue'),
		props: true,
		meta: { sideBar: 'Club' }
	},
	{
		path: '/club-team/:sid?',
		name: 'club-team',
		component: () => import('../views/ClubTeam.vue'),
		props: true,
		meta: { sideBar: 'Club' }
	},
	{
		path: '/club-team-member/:sid/:id',
		name: 'club-team-member',
		component: () => import('../views/ClubTeamMember.vue'),
		props: true,
		meta: { sideBar: 'Club' }
	},
	{
		path: '/articles',
		name: 'articles',
		component: () => import('../views/Articles.vue'),
		meta: { sideBar: 'Articles' }
	},
	{
		path: '/article/:id',
		name: 'article',
		component: () => import('../views/Article.vue'),
		props: true,
		meta: { sideBar: 'Articles' }
	},
	{
		path: '/web',
		name: 'web',
		component: () => import('../views/Web.vue'),
		meta: { sideBar: 'Web' }
	},
	{
		path: '/integrations',
		name: 'integrations',
		component: () => import('../views/Integrations.vue'),
		meta: { sideBar: 'Integrations' }
	},
	{
		path: '/customers',
		name: 'customers',
		component: () => import('../views/Customers.vue'),
		meta: { sideBar: 'Customers' }
	},
	{
		path: '/customer/:id',
		name: 'customer',
		component: () => import('../views/Customer.vue'),
		props: true,
		meta: { sideBar: 'Customers' }
	},











	{
		path: '/companies',
		name: 'companies',
		component: () => import('../views/Companies.vue'),
		meta: { sideBar: 'Companies' }
	},
	{
		path: '/company/:id',
		name: 'company',
		component: () => import('../views/Company.vue'),
		props: true,
		meta: { sideBar: 'Companies' }
	},

	{
		path: '/website', /// TODO unused for now
		name: 'website',
		component: () => import('../views/Website.vue'),
	},
	{ path: '/:pathMatch(.*)*', redirect: '/' }
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior() {
		document.getElementById('app').scrollIntoView();
	}
})

function check(to) {
	let input = {
		pathTo: to
	}
	return api
		.post("/auth/check", input)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			store.dispatch('error', error);
			return null;
		});
}

router.beforeEach((to, from, next) => {
	const preLoading = document.getElementById('loading_wrap');
	if (preLoading) {
		preLoading.style.display = 'none';
	}
	if (to.path === '/login') {
		TokenService.removeUser();
	}
	const publicPages = ['/login'];
	const authRequired = !publicPages.includes(to.path);
	const loggedIn = TokenService.getUser();

	if (authRequired && !loggedIn) {
		sessionStorage.setItem('redirectPath', to.path);
		next('/login');
	} else {
		if (to.path === '/login') {
			next();
		}
		else {
			check(to.path).then((response) => {
				if (response ? response.data.result === 'OK' : false) {
					store.dispatch('auth/setPermissions', response.data.permissions);
					next();
				}
				else {
					if (response) {
						store.dispatch('error', response.data);
						store.dispatch('reset');
						if (response.data.url === '/login') {
							sessionStorage.removeItem('redirectPath');
							store.dispatch('auth/logout');
						}
						else {
							if (response.data.url) next(response.data.url);
						}
					}
				}
			});
		}
	}
});

export default router;
