import { createStore } from 'vuex';
import { language } from './language.store';
import { auth } from './auth.store';
import { team } from './team.store';
import { game } from './game.store';
import { article } from './article.store';
import { customer } from './customer.store';


import { company } from './company.store';

import store from '@/store';
import { ElNotification } from 'element-plus';

export default createStore({
	state: {
	},
	notifications: {
		showSomething: {
			title: 'Something happened',
			message: 'See console for details',
			type: 'error'
		}
	},
	mutations: {
		reset(state) {
			team.state.items = [];
			game.state.items = [];
			article.state.items = [];
			customer.state.items = [];




			company.state.items = [];

		},
		error: (state, err) => {
			//console.log('store error - ', err);
			var msg = null;
			if (err.response) {
				msg = err.result ? err.result : err.response.data ? err.response.data.message : 'Error';
				//console.log('Error Response', err);
			} else if (err.request) {
				msg = err.result ? err.result : err.message ? err.message : 'Error';
				//console.log('Error Request', err);
			} else {
				msg = err.result ? err.result : err.message ? err.message : 'Error';
			}
			ElNotification.error({
				title: err.title ? err.title : err.name === 'AxiosError' ? 'Error' : err.name,
				message: msg,
				showClose: true,
				duration: 3000,
				type: 'error',
			});
		},
	},
	actions: {
		reset({ commit }) {
			commit('reset');
		},
		error({ commit }, data) {
			commit('error', data);
		},
	},
	modules: {
		language,
		auth,
		team,
		game,
		article,
		customer,



		company,
	},
	getters: {
		user: state => {
			var user = state.auth.user;
			return user ? user : [];
		},
		userPermissions: state => {
			var user = state.auth.user;
			return user ? user.permissions ? user.permissions : [] : [];
		},
		pagePermissions: state => {
			var user = state.auth.user;
			return user ? user.pagePermissions ? user.pagePermissions : [] : [];
		},
		hasPermission: (state) => (permission) => {
			var user = state.auth.user;
			var permissions = user ? user.pagePermissions ? user.pagePermissions : [] : [];
			var found = permissions.findIndex(element => element.hash === permission);
			if (found > -1) return true;
			return false;
		},
		userNotifications: state => {
			var user = state.auth.user;
			return user ? user.notifications ? user.notifications : [] : [];
		},
		userLanguage: state => {
			return state.language.language ? state.language.language : 'en';
		},
		languages: state => {
			var languages = state.language.languages;
			return languages ? languages : [];
		},
	},
	strict: process.env.NODE_ENV !== 'production'
})
