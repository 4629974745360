<template>
    <div class="login-wrapper">
        <div class="page-login-wrapper">
            <div class="card shadow">
                <div class="row" style="height: 400px;">
                    <div class="col-md-6">
                        <div class="p-3">
                            <div class="text-center mb-2 mt-3">
                                <icon name="logo" :width="260" :height="56" :boxw="376" :boxh="406"></icon>
                            </div>
                            <div v-if="showAction==='login'">
                                <h5 class="text-center text-uppercase mb-2">{{t('Label.Please.Login', 'Please login')}}</h5>
                                <el-form :model="login" :disabled="loading"
                                         ref="loginForm"
                                         size="small"
                                         label-position="top"
                                         label-width="0px"
                                         :hide-required-asterisk="true">
                                    <div>
                                        <el-form-item prop="email" :label="t('Label.Email', 'Email')"
                                                      :rules="[
                                                          { required: true, message: 'Please enter email address', trigger: 'blur' },
                                                          { type: 'email', message: 'Please enter correct email address', trigger: ['blur', 'change'] }
                                                          ]">
                                            <el-input ref="email" v-model="login.email" @blur="login.email = trimInput(login.email)" maxlength="100" prefix-icon="Message">
                                            </el-input>
                                        </el-form-item>
                                    </div>
                                    <div>
                                        <el-form-item prop="password" :label="t('Label.Password', 'Password')"
                                                      :rules="[{ required: true, message: 'Please enter password', trigger: 'blur' }]">
                                            <el-input ref="password" v-model="login.password" type="password" maxlength="100" show-password @change="submitLoginForm()" prefix-icon="Key">
                                            </el-input>
                                        </el-form-item>
                                    </div>
                                    <div>
                                        <el-button @click="submitLoginForm()" class="w-100 mt-3" icon="Check"
                                                   type="primary" size="small" round>{{t('Label.Login', 'Login')}}</el-button>
                                    </div>
                                </el-form>
                                <div>
                                    <el-button @click="changeView(1)" class="w-100 mt-3" icon="QuestionFilled"
                                               type="primary" size="small" plain round>{{t('Label.Forgot.Password', 'Forgot password?')}}</el-button>
                                </div>
                                <div class="pt-2" v-if="gglClientId!=='.....'">
                                    <GoogleLogin class="w-100" :callback="googleCallback" popup-type="TOKEN" :clientId="gglClientId">
                                        <el-button class="w-100 mt-3" type="primary" size="small" round>{{t('Label.Login.Google', 'Login with Google')}}</el-button>
                                    </GoogleLogin>
                                </div>
                            </div>
                            <div v-if="showAction==='check'">
                                <h5 class="text-center text-uppercase mb-2">{{t('Label.Verify.Email', 'Verify email')}}</h5>
                                <el-form :model="check" :disabled="loading"
                                         ref="checkForm"
                                         size="small"
                                         label-position="top"
                                         label-width="0px"
                                         :hide-required-asterisk="true">
                                    <div>
                                        <el-form-item prop="email" :label="t('Label.Email', 'Email')"
                                                      :rules="[
                                                          { required: true, message: 'Please enter email address', trigger: 'blur' },
                                                          { type: 'email', message: 'Please enter correct email address', trigger: ['blur', 'change'] }
                                                          ]">
                                            <el-input ref="email" v-model="check.email" @blur="check.email = trimInput(check.email)" maxlength="100" prefix-icon="Message">
                                            </el-input>
                                        </el-form-item>
                                    </div>
                                    <div>
                                        <el-button :disabled="loading"
                                                   @click="submitCheckForm()" class="w-100 mt-3" icon="Check"
                                                   size="small" type="primary" round>Submit</el-button>
                                    </div>
                                </el-form>
                                <div>
                                    <el-button @click="changeView(0)" class="mt-3 w-100" icon="Back"
                                               size="small" type="primary" plain round>Back to login</el-button>
                                </div>
                            </div>
                            <div v-if="showAction==='reset'">
                                <h5 class="text-center text-uppercase mb-1">Reset password</h5>
                                <el-form :model="reset" :disabled="loading"
                                         ref="resetForm"
                                         size="small"
                                         :rules="resetRules"
                                         label-position="top"
                                         label-width="0px"
                                         :hide-required-asterisk="true">
                                    <div>
                                        <div class="text-center fw-bold mb-1">{{ reset.email }}</div>
                                    </div>
                                    <div>
                                        <el-form-item prop="token" label="Please enter PIN Code from email">
                                            <el-input ref="token" v-model="reset.token" @blur="reset.token = trimInput(reset.token)" maxlength="50" prefix-icon="Menu">
                                            </el-input>
                                        </el-form-item>
                                    </div>
                                    <div class="row g-2">
                                        <div class="col-6">
                                            <div>
                                                <el-tooltip placement="top">
                                                    <template #content>
                                                        (minimum length 8 characters,
                                                        <br />must contain upper, lower case, numbers)
                                                    </template>
                                                    <el-form-item prop="password" label="Password">
                                                        <el-input v-model="reset.password" autocomplete="off" maxlength="100" show-password prefix-icon="Key">
                                                        </el-input>
                                                    </el-form-item>
                                                </el-tooltip>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div>
                                                <el-form-item prop="repeat" label="Repeat password">
                                                    <el-input v-model="reset.repeat" autocomplete="off" maxlength="100" show-password prefix-icon="Key">
                                                    </el-input>
                                                </el-form-item>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <el-button :disabled="loading"
                                                   @click="submitResetForm()" class="w-100 mt-3" icon="Check"
                                                   size="small" type="primary" round>Submit</el-button>
                                    </div>
                                </el-form>
                                <div>
                                    <el-button @click="changeView(0)" class="w-100 mt-3" icon="Back"
                                               size="small" type="primary" plain round>Back to login</el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 text-center d-none d-md-block">
                        <div class="d-flex flex-column w-100 h-100 p-3" style="background-size: cover; justify-content: center; border-radius: 5px;"
                             :style="{ backgroundImage: 'url(' + bg + ')' }">
                            <div v-if="message" class="alert alert-danger mt-3" role="alert">
                                {{ message }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="position-absolute top-0 p-1" v-if="1===2">
                    <span v-for="language in languages" :title="language.name">
                        <flag class="ms-1" style="width: 24px; cursor:pointer" :code="language.flag" @click="changeLanguage(language.code)"></flag>
                    </span>
                </div>
            </div>
        </div>
        <Loading v-if="loading" />
    </div>
</template>
<script>//begin

    import AuthService from '../services/auth.service';
    import { defineComponent, ref } from 'vue'
    import Loading from '@/components/Loading.vue';
    import { GoogleLogin } from 'vue3-google-login';
    export default {
        name: "Login",
        components: {
            Loading,
            GoogleLogin,
        },
        data() {

            var validatePassRepeat = (rule, value, callback) => {
                if (value === undefined || value === '') {
                    callback(new Error('Please enter the same password'))
                } else if (value !== this.reset.password) {
                    callback(new Error("Passwords do not match!"))
                } else {
                    callback()
                }
            }

            return {
                bg: require("@/assets/bg.jpg"),
                showAction: 'login',
                selectedVideo: '',
                loading: true,
                message: "",
                login: { email: '', password: '' },
                check: { email: '' },
                reset: { email: '', token: '', password: '', repeat: '' },
                loginExt: { token: '', provider: '' },
                resetRules: {
                    token: [{ required: true, message: 'Please enter PIN Code', trigger: 'blur' },],
                    password: [{ required: true, pattern: /^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d\W])|(?=.*\W)(?=.*\d))|(?=.*\W)(?=.*[A-Z])(?=.*\d)).{8,}$/, message: 'Please input secure password', trigger: 'blur' }],
                    repeat: [{ validator: validatePassRepeat, trigger: 'blur' }],
                },
                gglClientId: '.....',
            };
        },
        computed: {
            development() {
                return process.env.NODE_ENV === 'development' && this.showAction === 'login';
            },
            languages() {
                return this.$store.getters.languages;
            },
        },
        mounted() {
            var self = this;
            this.$nextTick(function () {
                self.$store.dispatch('reset');
                setTimeout(() => { try { self.loading = false; self.$refs['email'].focus(); self.getExternalLogins() } catch (e) { } }, 50);
            });
        },
        methods: {
            changeLanguage(lang) {
                this.$store.dispatch('language/set', lang);
            },
            changeView(view) {
                this.resetForms();
                if (view === 0) {
                    this.showAction = 'login';
                    this.$nextTick(function () {
                        setTimeout(() => { try { this.$refs['email'].focus(); } catch (e) { } }, 50);
                    });
                }
                if (view === 1) {
                    this.showAction = 'check';
                    this.$nextTick(function () {
                        setTimeout(() => { try { this.$refs['email'].focus(); } catch (e) { } }, 50);
                    });
                }
                if (view === 2) {
                    this.showAction = 'reset';
                    this.$nextTick(function () {
                        setTimeout(() => { try { this.$refs['token'].focus(); } catch (e) { } }, 50);
                    });
                }
            },
            getExternalLogins() {
                return AuthService.getExternalLogins().then(
                    data => {
                        if (data && data.length > 0) {
                            let ggl = data.find(item => item.provider === 'Google');
                            if (ggl.providerID) {
                                this.gglClientId = ggl.providerID;
                            }
                        }
                        return Promise.resolve(data);
                    },
                    error => {
                        return Promise.reject(error);
                    }
                );
            },
            googleCallback(response) {
                if (!response.access_token) return;
                this.loading = true;
                this.message = "";
                this.$store.dispatch('reset');
                this.loginExt.provider = 'Google';
                this.loginExt.token = response.access_token;
                this.$store.dispatch('auth/loginExt', this.loginExt).then(
                    (data) => {
                        this.loading = false;
                        this.resetForms();
                        this.$router.replace(sessionStorage.getItem('redirectPath') || '/');
                        sessionStorage.removeItem('redirectPath');
                    },
                    (error) => {
                        this.loading = false;
                        this.message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    }
                );
            },
            submitLoginForm() {
                this.$refs['loginForm'].validate((valid, fields) => {
                    if (valid) {
                        this.handleLogin(this.login);
                    } else {
                        this.focusInvalid(fields);
                        return false;
                    }
                })
            },
            handleLogin(user) {
                this.loading = true;
                this.message = "";
                this.$store.dispatch('reset');
                this.$store.dispatch('auth/login', user).then(
                    () => {
                        this.loading = false;
                        this.resetForms();
                        this.$router.replace(sessionStorage.getItem('redirectPath') || '/');
                        sessionStorage.removeItem('redirectPath');
                    },
                    (error) => {
                        this.loading = false;
                        this.message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

                        this.$nextTick(function () {
                            setTimeout(() => { try { this.$refs['email'].focus(); } catch (e) { } }, 50);
                        });
                    }
                );
            },
            submitCheckForm() {
                this.$refs['checkForm'].validate((valid, fields) => {
                    if (valid) {
                        this.handleCheck(this.check);
                    } else {
                        this.focusInvalid(fields);
                        return false;
                    }
                })
            },
            handleCheck(data) {
                this.loading = true;
                this.message = "";
                return AuthService.checkEmail(data).then(
                    data => {
                        this.loading = false;
                        this.resetForms();
                        if (data.email) {
                            this.changeView(2);
                            this.reset.email = data.email;
                        }
                        else {
                            this.message = "Email not found...";

                            this.$nextTick(function () {
                                setTimeout(() => { try { this.$refs['email'].focus(); } catch (e) { } }, 50);
                            });
                        }
                        return Promise.resolve(data);
                    },
                    error => {
                        return Promise.reject(error);
                    }
                );
            },
            submitResetForm() {
                this.$refs['resetForm'].validate((valid, fields) => {
                    if (valid) {
                        this.handleReset(this.reset);
                    } else {
                        this.focusInvalid(fields);
                        return false;
                    }
                })
            },
            handleReset(data) {
                this.loading = true;
                this.message = "";
                return AuthService.resetPassword(data).then(
                    data => {
                        this.loading = false;
                        this.resetForms();
                        if (data == "OK") {
                            this.changeView(0);

                            this.$nextTick(function () {
                                setTimeout(() => { try { this.$refs['email'].focus(); } catch (e) { } }, 50);
                            });
                        }
                        else {
                            this.message = data;
                        
                            this.$nextTick(function () {
                                setTimeout(() => { try { this.$refs['token'].focus(); } catch (e) { } }, 50);
                            });
                        }
                        return Promise.resolve(data);
                    },
                    error => {
                        return Promise.reject(error);
                    }
                );
            },
            resetForms() {
                this.message = '';
                this.login = { email: '', password: '' };
                this.check = { email: '' };
                this.reset = { email: '', token: '', password: '', repeat: '' };
            }
        },
    };</script>
