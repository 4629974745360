import CommonService from "../services/common.service";

export const game = {
	namespaced: true,
	state: {
		items: []
	},
	actions: {
		getGames({ commit }) {
			return CommonService.getGames().then(
				data => {
					commit('fill_OK', data);
					return Promise.resolve(data);
				},
				error => {
					commit('fill_ERR');
					return Promise.reject(error);
				}
			);
		},




		search({ commit }, data) {
			return CommonService.getGames(data).then(
				data => {
					commit('fill_OK', data);
					return Promise.resolve(data);
				},
				error => {
					commit('fill_ERR');
					return Promise.reject(error);
				}
			);
		},
		update({ commit }, data) {
			commit('update_OK', JSON.parse(JSON.stringify(data)));
		},
	},
	mutations: {
		fill_OK(state, items) {
			state.items = items;
		},
		fill_ERR(state) {
			state.items = [];
		},
		update_OK: (state, item) => {
			delete item.tickets;
			const existsAtIndex = state.items.findIndex(u => u.gameID === item.gameID);
			if (existsAtIndex !== -1) {
				state.items[existsAtIndex] = item;
			} else {
				state.items.unshift(item);
			}
			state.items = [...state.items];
		},

	}
};

