import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import TokenService from "./services/token.service";

import setupInterceptors from './services/setupInterceptors';

import Icon from './components/Icon';
import Flag from './components/Flag';

import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap';

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
//import 'element-plus/theme-chalk/dark/css-vars.css';

import CKEditor from '@ckeditor/ckeditor5-vue';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';

import _ from 'lodash';
//import CryptoJS from 'crypto-js';

import './assets/site-el.css';
import './assets/site.css';

dayjs.Ls.en ??= {};
dayjs.Ls.en.weekStart = 1;

/*
let txt = 'tadas';
let enc = this.encrypt_JS(txt);
let dec = this.decrypt_JS(enc);

var res = new Date().toISOString().slice(0, 10).replace(/-/g, '');

console.log('log ', txt, dec, res);
*/

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

//import VueTelInput from 'vue-tel-input';
//import 'vue-tel-input/dist/vue-tel-input.css';

//import Schema from 'async-validator';
//Schema.warning = function () { };
globalThis.ASYNC_VALIDATOR_NO_WARNING = 1;
globalThis.__VUE_OPTIONS_API__ = true;
globalThis.__VUE_PROD_DEVTOOLS__ = false;
globalThis.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false;

setupInterceptors(store);

const app = createApp(App)
	.use(store)
	.use(router)
	.use(ElementPlus)
	.use(CKEditor)
	//.use(VueTelInput)
	.mixin({
		methods: {
			hasPermission(permission) {
				if (permission.length !== 36) {
					console.log('wrong permission', permission);
					return false;
				}
				let has = store.getters.hasPermission(permission);
				return has;
			},
			capitalizeFirstLetter(str) {
				return str.charAt(0).toUpperCase() + str.slice(1);
			},
			trimInput(str) {
				try {
					if (str) {
						return str.trim();
					}
				} catch (e) {
					return str;
				}
			},
			fixDate(str) {
				//if (typeof str === 'string') {
					//str = str.replace(/-/g, '/');
					//str = str.replace(/\./g, '/');
				//}
				//let dt = new Date(str);
				//console.log('fixDate', str, dt);
				return dayjs(str);
			},
			formatDateTime(str, format = 'YYYY.MM.DD HH:mm:ss') {
				return this.fixDate(str).format(format);
			},
			formatDate(str, format = 'YYYY.MM.DD') {
				return this.fixDate(str).format(format);
			},
			roundDecimal(num, decimalPlaces = 4) {
				let p = Math.pow(10, decimalPlaces);
				let n = (num * p) * (1 + Number.EPSILON);
				return Math.round(n) / p;
			},
			showPrice(num, decimalPlaces = 2, currency = 'EUR') {
				try {
					if (num === undefined || num === '') return '';
					let val = this.roundDecimal(num, decimalPlaces);
					if (!currency || currency === '') currency = 'EUR';
					if (currency !== 'EUR' && currency !== 'USD' && currency !== 'GBP' && currency !== 'CNY') currency = 'EUR';
					let locale = 'lt-LT';
					if (currency === 'USD') locale = 'en-US';
					if (currency === 'GBP') locale = 'en-GB';
					return val.toLocaleString(locale, { maximumFractionDigits: decimalPlaces, minimumFractionDigits: 2, style: 'currency', currency: currency, currencyDisplay: 'symbol' });
				}
				catch (e) {
					//console.log('showPrice error', e, num, decimalPlaces, currency);
					return '';
				}
			},
			showDecimal(num, decimalPlaces = 4, removeZeros = 1) {
				let val = this.roundDecimal(num, decimalPlaces);
				let res = val.toLocaleString("lt-LT", { maximumFractionDigits: decimalPlaces, minimumFractionDigits: decimalPlaces });
				if (removeZeros === 1) {
					if (res.match(/\,/)) {
						res = res.replace(/\,?0+$/, '');
					}
				}
				return res;
			},
			zeroIfNull(input) {
				input ??= 0;
				return input !== '' && input >= 0 ? input : 0;
			},
			notify(title, message) {
				let type = 'success';
				if (title === 'Error') type = 'error';
				if (title === 'Info') type = 'warning';
				if (title === 'Copied') type = 'info';
				this.$notify({
					title: title, message: message, showClose: true, type: type, duration: title === 'Error' ? 5000 : 1500,
				});
			},
			getFileUrl(id, param = 100) {
				if (id === undefined) return '';
				return '/api/file/get/' + id + (param && param !== 100 ? '/' + param : '');
			},
			getSecureFileUrl(id, param = 100) {
				try {
					const token = TokenService.getLocalAccessToken();
					if (token) {
						console.log('getSecureFileUrl', '/api/file/getsecure/' + id + '/' + token + (param && param !== 100 ? '/' + param : ''));
						return '/api/file/getsecure/' + id + '/' + token + (param && param !== 100 ? '/' + param : '');
					}
					else {
						return '';
					}
				}
				catch (e) { return ''; }
			},
			focusInvalid(fields) {
				console.log('fields', fields);
				let fieldsArr = Object.keys(fields);
				for (let i = 0; i < fieldsArr.length; i++) {
					if (Array.isArray(fields[fieldsArr[i]])) {
						if (fields[fieldsArr[i]][0].field) {
							let field = this.$refs[fields[fieldsArr[i]][0].field];
							if (field) {
								this.$nextTick(function () {
									setTimeout(() => { try { field.focus(); } catch (e) { try { field[0].focus(); } catch (e) { } } }, 50);
								});
							}
							else {
								let refField = fields[fieldsArr[i]][0].field;
								if (refField === 'country') {
									let refForm = this.$refs['addressForm'];
									if (refForm) {
										try { refForm.focusFormInput(refField); } catch (e) { }
									}
								}
							}
						}
						return
					}
				}
			},
			copyToClipboard(text) {
				try {
					const clipboardData =
						event.clipboardData ||
						window.clipboardData ||
						event.originalEvent?.clipboardData ||
						navigator.clipboard;
					clipboardData.writeText(text);
					this.notify('Copied', text);
				}
				catch (e) { }
			},
			t(label, fallback) {
				try {
					let languages = store.getters.languages;
					if (languages.length === 0) {
						return fallback;
					}
					let userLanguage = store.getters.userLanguage;
					let texts = languages.filter((item) => {
						return item.code === userLanguage;
					})[0].texts;
					if (!texts) {
						console.log('!texts');
						return fallback;
					}
					let value = texts.filter((item) => {
						return item.name === label;
					});
					if (!value[0]) {
						//console.log('no translation', label, userLanguage);//todo
						return fallback;
					}
					value = value[0].value;
					return value ? value : fallback;
				}
				catch (e) {
					console.log('t error', e, label);
					return fallback;
				}
			},
			//encrypt_JS(text) {
			//	let _result = CryptoJS.AES.encrypt(JSON.stringify(text), new Date().toISOString().slice(0, 10).replace(/-/g, '')).toString().replace(/\+/g, '-').replace(/\//g, '_');//.replace(/=+$/, '');
			//	return _result;
			//},
			//decrypt_JS(text) {
			//	let _bytes = CryptoJS.AES.decrypt(text.replace(/\-/g, '+').replace(/\_/g, '/'), new Date().toISOString().slice(0, 10).replace(/-/g, ''));
			//	let _result = JSON.parse(_bytes.toString(CryptoJS.enc.Utf8));
			//	return _result;
			//},
		},
	})
	.component('icon', Icon)
	.component('flag', Flag)
	/*
	.component('Loading', Loading)
	*/

dayjs.extend(relativeTime);
dayjs.extend(duration);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
	app.component(key, component)
}

app.config.globalProperties.$dayjs = dayjs;
app.config.productionTip = false;

app.mount('#app');
