import CommonService from "../services/common.service";

export const article = {
	namespaced: true,
	state: {
		items: []
	},
	actions: {
		getArticles({ commit }) {
			return CommonService.getArticles().then(
				data => {
					commit('fill_OK', data);
					return Promise.resolve(data);
				},
				error => {
					commit('fill_ERR');
					return Promise.reject(error);
				}
			);
		},

		search({ commit }, data) {
			return CommonService.getArticles(data).then(
				data => {
					commit('fill_OK', data);
					return Promise.resolve(data);
				},
				error => {
					commit('fill_ERR');
					return Promise.reject(error);
				}
			);
		},
		update({ commit }, data) {
			commit('update_OK', JSON.parse(JSON.stringify(data)));
		},
		delete({ commit }, data) {
			commit('delete_OK', JSON.parse(JSON.stringify(data)));
		},
	},
	mutations: {
		fill_OK(state, items) {
			state.items = items;
		},
		fill_ERR(state) {
			state.items = [];
		},
		update_OK: (state, item) => {
			delete item.blocks;
			delete item.elements;
			const existsAtIndex = state.items.findIndex(u => u.articleID === item.articleID);
			if (existsAtIndex !== -1) {
				state.items[existsAtIndex] = item;
			} else {
				state.items.unshift(item);
			}
			state.items = [...state.items];
		},
		delete_OK: (state, item) => {
			state.items = state.items.filter(u => u.articleID !== item.articleID);
			state.items = [...state.items];
		},
	}
};
