import AuthService from '../services/auth.service';
import TokenService from '../services/token.service';
import router from '../router';
import store from '@/store'

const user = TokenService.getUser();
const initialState = user ? { status: { loggedIn: true }, user, interval: null } : { status: { loggedIn: false }, user: null, interval: null };

export const auth = {
	namespaced: true,
	state: initialState,
	actions: {
		login({ commit }, login) {
			return AuthService.login(login).then(
				user => {
					if (!user.userID > 0) {
						commit('login_ERR');
						return Promise.reject(user);
					}
					commit('login_OK', user);
					return Promise.resolve(user);
				},
				error => {
					commit('login_ERR');
					return Promise.reject(error);
				}
			);
		},
		loginExt({ commit }, login) {
			return AuthService.loginExt(login).then(
				user => {
					if (!user.userID > 0) {
						commit('login_ERR');
						return Promise.reject(user);
					}
					commit('login_OK', user);
					return Promise.resolve(user);
				},
				error => {
					commit('login_ERR');
					return Promise.reject(error);
				}
			);
		},
		logout({ commit }) {
			commit('logout');
		},
		setPermissions({ commit }, data) {
			commit('setPermissions_OK', data);
		},
		refreshLogin({ commit }, data) {
			commit('refreshLogin_OK', data);
		},
		refreshLoginFailed({ commit }) {
			commit('logout');
		},
		startNotifications({ commit }, data) {
			return;



			///console.log('startNotifications', data.companyName, new Date());
			store.dispatch('auth/getNotifications', data);
			let timer = 30 * 1000;
			let interval = setInterval(() => {
				store.dispatch('auth/getNotifications', data);
			}, timer);
			commit('startNotifications_OK', interval);
		},
		getNotifications({ commit }, data) {
			///console.log('getNotifications', data.companyName, new Date());
			return AuthService.getNotifications(data).then(
				data => {
					commit('getNotifications_OK', data);
					return Promise.resolve(data);
				},
				error => {
					commit('getNotifications_ERR');
					return Promise.reject(error);
				}
			);
		},
	},
	mutations: {
		setPermissions_OK(state, permissions) {
			if (!permissions) return;
			state.user.pagePermissions = permissions;
		},
		login_OK(state, user) {
			if (state.user ? state.user.interval : false) {
				clearInterval(state.user.interval);
				state.user.interval = null;
			}
			state.status.loggedIn = true;
			state.user = user;
			TokenService.setUser(user);
			store.dispatch('language/set', (user.langCode ? user.langCode.substring(0, 2).toLocaleLowerCase() : 'en'));
		},
		refreshLogin_OK(state, user) {
			state.user.jwtToken = user.jwtToken;
			TokenService.updateToken(user);
		},
		login_ERR(state) {
			if (state.user ? state.user.interval : false) {
				clearInterval(state.user.interval);
				state.user.interval = null;
			}
			state.status.loggedIn = false;
			state.user = null;
		},
		logout(state) {
			try {
				AuthService.logout();
				state.status.loggedIn = false;
				if (state.user ? state.user.interval : false) {
					clearInterval(state.user.interval);
					state.user.interval = null;
				}
				state.user = null;
				router.replace('/login');
			}
			catch (e) { }
		},
		getNotifications_OK(state, notifications) {
			let oldNotifications = JSON.stringify(state.user.notifications);
			let newNotifications = JSON.stringify(notifications);
			if (oldNotifications !== newNotifications) state.user.notifications = notifications;
		},
		getNotifications_ERR(state) {
			state.user.notifications = [];
		},
		startNotifications_OK(state, interval) {
			if (state.user ? state.user.interval : false) {
				clearInterval(state.user.interval);
				state.user.interval = null;
			}
			state.user.interval = interval;
		},
	}
};

