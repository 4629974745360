import api from './api';
import store from '../store';

class CommonService {

	testCall(input) {
		return api
			.post("/common/test-call", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getParameters(input) {
		return api
			.post("/common/get-parameters", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveParameterValues(input) {
		return api
			.post("/common/save-parameters", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getPermissions() {
		return api
			.get("/common/get-permissions")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getUsers() {
		return api
			.get("/common/get-users")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getUser(input) {
		return api
			.post("/common/get-user", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}
	
	saveUser(input) {
		return api
			.post("/common/save-user", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteUser(input) {
		return api
			.post("/common/delete-user", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getSeasons() {
		return api
			.get("/common/get-seasons")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveSeason(input) {
		return api
			.post("/common/save-season", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteSeason(input) {
		return api
			.post("/common/delete-season", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getLeagues() {
		return api
			.get("/common/get-leagues")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveLeague(input) {
		return api
			.post("/common/save-league", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteLeague(input) {
		return api
			.post("/common/delete-league", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getPartners() {
		return api
			.get("/common/get-partners")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	savePartner(input) {
		return api
			.post("/common/save-partner", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deletePartner(input) {
		return api
			.post("/common/delete-partner", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	savePartnerSortOrder(input) {
		return api
			.post("/common/save-partner-sort", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getVenues() {
		return api
			.get("/common/get-venues")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveVenue(input) {
		return api
			.post("/common/save-venue", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteVenue(input) {
		return api
			.post("/common/delete-venue", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getTeams() {
		return api
			.get("/common/get-teams")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveTeam(input) {
		return api
			.post("/common/save-team", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getTeam(input) {
		return api
			.post("/common/get-team", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getGames() {
		return api
			.get("/common/get-games")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveGame(input) {
		return api
			.post("/common/save-game", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveGameTickets(input) {
		return api
			.post("/common/save-game-tickets", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteGameTicket(input) {
		return api
			.post("/common/delete-game-ticket", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getGame(input) {
		return api
			.post("/common/get-game", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}


	getClubPersonnel() {
		return api
			.get("/common/get-club-personnel")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}
	
	saveClubPersonnelSortOrder(input) {
		return api
			.post("/common/save-club-personnel-sort", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getPersonnelGroups() {
		return api
			.get("/common/get-personnel-groups")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}
	
	saveClubPersonnel(input) {
		return api
			.post("/common/save-club-personnel", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}
	
	getClubPersonnelElements() {
		return api
			.get("/common/get-club-personnel-elements")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}


//ambassadors




	getClubAmbassadors() {
		return api
			.get("/common/get-club-ambassadors")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getClubAmbassador(input) {
		return api
			.post("/common/get-club-ambassador", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getClubAmbassadorElements() {
		return api
			.get("/common/get-club-ambassador-elements")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveClubAmbassadorSortOrder(input) {
		return api
			.post("/common/save-club-ambassadors-sort", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveClubAmbassador(input) {
		return api
			.post("/common/save-club-ambassador", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}
	
	saveClubAmbassadorBlock(input) {
		return api
			.post("/common/save-club-ambassador-block", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveClubAmbassadorBlockSortOrder(input) {
		return api
			.post("/common/save-club-ambassador-block-sort", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteClubAmbassadorBlock(input) {
		return api
			.post("/common/delete-club-ambassador-block", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveClubAmbassadorTextBlockContent(input) {
		return api
			.post("/common/save-club-ambassador-text-block-content", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}














//club



	getClubTeam(input) {
		return api
			.post("/common/get-club-team", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getClubTeamMembers(input) {
		return api
			.post("/common/get-club-team-members", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getClubTeamMember(input) {
		return api
			.post("/common/get-club-team-member", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveClubTeamMember(input) {
		return api
			.post("/common/save-club-team-member", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveClubTeamMemberSortOrder(input) {
		return api
			.post("/common/save-club-team-member-sort", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveClubTeamMemberBlock(input) {
		return api
			.post("/common/save-club-team-member-block", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveClubTeamMemberBlockSortOrder(input) {
		return api
			.post("/common/save-club-team-member-block-sort", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteClubTeamMemberBlock(input) {
		return api
			.post("/common/delete-club-team-member-block", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveClubTeamMemberTextBlockContent(input) {
		return api
			.post("/common/save-club-team-member-text-block-content", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getClubTeamMemberElements() {
		return api
			.get("/common/get-club-team-member-elements")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	


	
//acticles

	getArticles(input) {
		return api
			.post("/common/get-articles", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getArticle(input) {
		return api
			.post("/common/get-article", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getArticleCategories() {
		return api
			.get("/common/get-article-categories")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}
	
	saveArticle(input) {
		return api
			.post("/common/save-article", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteArticle(input) {
		return api
			.post("/common/delete-article", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	activateArticle(input) {
		return api
			.post("/common/activate-article", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getArticleElements() {
		return api
			.get("/common/get-article-elements")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveArticleBlock(input) {
		return api
			.post("/common/save-article-block", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveArticleBlockSortOrder(input) {
		return api
			.post("/common/save-article-block-sort", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteArticleBlock(input) {
		return api
			.post("/common/delete-article-block", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}
	
	getContentBlocks(input) {
		return api
			.post("/common/get-content-blocks", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	activateBlockContent(input) {
		return api
			.post("/common/activate-block-content", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}
	
	saveArticleTextBlockContent(input) {
		return api
			.post("/common/save-article-text-block-content", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}















//web


	getWebPages() {
		return api
			.get("/common/get-web-pages")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getWebPage(input) {
		return api
			.post("/common/get-web-page", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveWebPageBlock(input) {
		return api
			.post("/common/save-web-page-block", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveWebPageBlockSortOrder(input) {
		return api
			.post("/common/save-web-page-block-sort", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}
	
	deleteWebPageBlock(input) {
		return api
			.post("/common/delete-web-page-block", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getWebPageElements() {
		return api
			.get("/common/get-web-page-elements")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}
	
	saveWebPage(input) {
		return api
			.post("/common/save-web-page", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveWebPageTextBlockContent(input) {
		return api
			.post("/common/save-web-page-text-block-content", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveImageSliderBlockSlide(input) {
		return api
			.post("/common/save-image-slider-block-slide", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteImageSliderBlockSlide(input) {
		return api
			.post("/common/delete-image-slider-block-slide", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveImageSliderBlockSlideSortOrder(input) {
		return api
			.post("/common/save-image-slider-block-slide-sort", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	} 

	saveRequestFormBlock(input) {
		return api
			.post("/common/save-request-form-block", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveInvestorsFormBlock(input) {
		return api
			.post("/common/save-investors-form-block", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveGroupTicketsFormBlock(input) {
		return api
			.post("/common/save-group-tickets-form-block", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveSubscribeBlock(input) {
		return api
			.post("/common/save-subscribe-block", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}



	//above OK


	getPlatformLanguages() {
		return api
			.get("/common/get-platform-languages")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getCountries(input) {
		return api
			.post("/common/get-countries", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getWebLanguages() {
		return api
			.get("/common/get-web-languages")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	listLanguages(input) {
		return api
			.post("/common/list-languages", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getLanguages() {
		return api
			.get("/common/get-languages")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveLanguage(input) {
		return api
			.post("/common/save-language", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteLanguage(input) {
		return api
			.post("/common/delete-language", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveLanguageSortOrder(input) {
		return api
			.post("/common/save-language-sort", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getMarkets() {
		return api
			.get("/common/get-markets")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveMarket(input) {
		return api
			.post("/common/save-market", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	restartMarketWebsite(input) {
		return api
			.post("/common/restart-market-website", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteMarket(input) {
		return api
			.post("/common/delete-market", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveMarketSortOrder(input) {
		return api
			.post("/common/save-market-sort", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}



	getWebLogs() {
		return api
			.get("/common/get-weblogs")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}
	
	getPaymentOptions() {
		return api
			.get("/common/get-payment-options")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	savePaymentOptions(input) {
		return api
			.post("/common/save-payment-options", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getSettings() {
		return api
			.get("/common/get-settings")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveSettings(input) {
		return api
			.post("/common/save-settings", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	checkPdfFile(input) {
		return api
			.post("/file/check-pdf", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	checkFile(input) {
		return api
			.post("/file/check", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getFiles(input) {
		return api
			.post("/file/list", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteFiles(input) {
		return api
			.post("/file/delete", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveFileSortOrder(input) {
		return api
			.post("/file/save-file-sort", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	pingServer() {
		return api.get('/auth/ping');
	}

	getWebObjects() {
		return api
			.get("/common/get-web-objects")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveWebObjectElement(input) {
		return api
			.post("/common/save-web-object-element", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getWebObjectSources(input) {
		return api
			.post("/common/get-web-object-sources", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getWebObjectTranslation(input) {
		return api
			.post("/common/get-web-object-translation", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveWebObjectTranslation(input) {
		return api
			.post("/common/save-web-object-translation", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getDiscountProfiles(input) {
		return api
			.post("/common/get-discount-profiles", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	async getGeoData(search) {
		const action = search.action;
		const country = encodeURIComponent(search.countryCode);
		const region1 = encodeURIComponent(search.region1);
		const region2 = encodeURIComponent(search.region2);
		const region3 = encodeURIComponent(search.region3);
		const city = encodeURIComponent(search.city);
		const post = encodeURIComponent(search.post);
		const text = encodeURIComponent(search.text);

		var url = '';

		if (action === 'country') {
			url += '&rows=1&facet=admin_name1&refine.country_code=' + country;
		}

		if (action === 'region1') {
			url += '&rows=1&facet=admin_name2&facet=admin_name3&refine.country_code=' + country;
			url += '&refine.admin_name1=' + region1;
		}

		if (action === 'region2') {
			url += '&rows=1&facet=admin_name3&refine.country_code=' + country;
			url += '&refine.admin_name1=' + region1;
			url += '&refine.admin_name2=' + region2;
		}

		if (action === 'region3' || action === 'city' || action === 'postCode') {
			url += '&rows=10000&facet=admin_name3&refine.country_code=' + country;
			url += '&refine.admin_name1=' + region1;
			if (region2 != '') url += '&refine.admin_name2=' + region2;
			if (region3 != '') url += '&refine.admin_name3=' + region3;
			if (city != '') url += '&refine.place_name=' + city;
			if (post != '') url += '&refine.postal_code=' + post;
		}

		if (text !== undefined && text !== 'undefined' && text !== '') {
			url += '&q=' + text;
		}

		if (url == '') return;

		url = 'https://public.opendatasoft.com/api/records/1.0/search/?dataset=geonames-postal-code' + url;


		///                                   api/explore/v2.1/catalog/datasets/geonames-postal-code/records?limit=20
		///                                   api/explore/v2.1/catalog/datasets/geonames-all-cities-with-a-population-1000/records?limit=20&refine=cou_name_en%3A%22Lithuania%22

		const response = await fetch(url);
		return await response.json();

	}

	async getWebCities(letter, country) {
		letter = encodeURIComponent(letter);
		country = encodeURIComponent(country);
		const response = await fetch(
			`https://public.opendatasoft.com/api/records/1.0/search/?dataset=geonames-postal-code&rows=1&facet=admin_name2&facet=admin_code2&refine.country_code=${country}`
		);
		return await response.json();
	}

	async getXWebCities(letter, country) {
		const regex = "^[" + letter.charAt(0).toUpperCase() + "" + letter.charAt(0).toLowerCase() + "]";
		const where = encodeURIComponent(JSON.stringify({
			"country": {
				"__type": "Pointer",
				"className": "Continentscountriescities_Country",
				"objectId": country
			},
			"name": {
				"$regex": regex
			}
		}));
		const response = await fetch(
			`https://parseapi.back4app.com/classes/Continentscountriescities_City?limit=9999&order=name&keys=name&where=${where}`,
			{
				headers: {
					'X-Parse-Application-Id': 'ZN0EMi73hIj4C0XZCLVB0KAwtRmBET4r3RbJTQx2',
					'X-Parse-REST-API-Key': 'DaMUksNKRDThLBF9aIJEFnlO7VTE04ZBx22oJeo1',
				}
			}
		);
		return await response.json(); // Here you have the data that you need
	}






	

}

export default new CommonService();
