import axiosInstance from "./api";
import TokenService from "./token.service";

const setup = (store) => {

	let isRefreshing = false;
	let subscribers = [];

	function subscribeTokenRefresh(cb) {
		subscribers.push(cb);
	}

	function onRrefreshed(token) {
		subscribers.map((cb) => cb(token));
	}

	axiosInstance.interceptors.request.use(
		(config) => {
			const token = TokenService.getLocalAccessToken();
			if (token) {
				config.headers['Authorization'] = 'Bearer ' + token;
			}
			config.headers["Content-Type"] = "application/json";
			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	axiosInstance.interceptors.response.use(
		(res) => {
			return res;
		},
		async (err) => {
			if (err.code === 'ERR_NETWORK') {
				return Promise.reject(err);
			}
			//console.log('async (err) => ', err);
			const {
				config,
				response: { status },
			} = err;
			const originalRequest = config;
			if (originalRequest.url !== '/auth/authenticate') {
				if (status === 401 && !originalRequest._retry) {
					originalRequest._retry = true;
					try {
						if (!isRefreshing) {
							isRefreshing = true;
							//console.log('refreshtoken call', new Date().toTimeString());
							axiosInstance.post('/auth/refreshtoken').then((rs) => {
								store.dispatch('auth/refreshLogin', rs.data);
								isRefreshing = false;
								onRrefreshed(rs.data.jwtToken);
								subscribers = [];
							}).catch((rs_err) => {
								subscribers = [];
								isRefreshing = false;
								store.dispatch('auth/refreshLoginFailed');
							});
						}
						return new Promise((resolve) => {
							subscribeTokenRefresh((token) => {
								originalRequest.headers["Authorization"] = 'Bearer ' + token;
								resolve(axiosInstance(originalRequest));
							});
						});
					}
					catch (_error) {
						subscribers = [];
						isRefreshing = false;
						store.dispatch('auth/refreshLoginFailed');
						return Promise.reject(_error);
					}
				}
			}
			return Promise.reject(err);
		});
};

export default setup;